.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.scroll-snap-container {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
}

.scroll-snap-align-start {
    scroll-snap-align: start;
}

li {
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
}

.scroll-snap-container::-webkit-scrollbar {
    width: 10px;
    /* Adjust width of the scrollbar here */
}

.scroll-snap-container::-webkit-scrollbar-track {
    background: #d1d5db;
    /* Adjust track color/background here */
}

.scroll-snap-container::-webkit-scrollbar-thumb {
    background: #4b5563;
    /* Adjust thumb color/background here */
    border-radius: 4px;
    /* Optional: Add rounded corners to the thumb */
}

.scroll-snap-container::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Adjust thumb hover color/background here */
}